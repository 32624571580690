import React, { useContext } from 'react';
import { StyleSheet, View } from 'react-native';
import { ThemeContext } from '../../../../providers/theme';
import config from '../../config/default-blueprint.json';
import { useViewport } from 'react-viewport-provider';
import { LandingPageV } from 'react-landing-page-template-1';
import { EventBus } from 'event-bus-vfs';
import { FORM_VALUES, defaultValuesDS } from 'react-form-rs';
import Alert from 'rsuite/lib/Alert';
import { IProps } from './index';

const BP = config.LandingPage;
let formContext = {};

export const LandingTemplateLG = (props: IProps) => {
  const theme = useContext(ThemeContext);
  const { width, height } = useViewport();
  const [validateForm, setValidateForm] = React.useState<boolean>(false);
  const [date, setDate] = React.useState<Date>(new Date());
  const styles = StyleSheet.create({
    containerStyle: {
      width: 1 * width,
      height: 1 * height,
      backgroundColor: '#FAFBFC',
    },
  });

  const handleValidateResult = (
    formContext: any,
    status: 'success' | 'error',
    validateResult: any,
  ) => {
    if (status === 'success') {
      props.onGetStarted &&
        props.onGetStarted(defaultValuesDS.get('emailAddress')?.defaultValue);
      defaultValuesDS.clear();
    }
    if (status === 'error') {
      BP.getStartedFFEC && Alert.error(BP.getStartedFFEC, 10000);
    }
    setDate(new Date());
    setValidateForm(false);
  };

  return (
    <View style={StyleSheet.flatten([styles.containerStyle])}>
      <LandingPageV
        //containerStyle={StyleSheet.flatten([styles.containerStyle])}
        title={BP.title}
        leftAvatar={BP.leftAvatarURL}
        loginButtonTitle={BP.loginButtonTitle}
        loginButtonColor={BP.loginButtonColor}
        signupButtonTitle={BP.signupButtonTitle}
        signupButtonColor={theme?.palette.secondary}
        onLoginButtonPress={props.onLoginClick}
        onSignupButtonPress={props.onSignupClick}
        bodyTitle={BP.bodyTitle}
        bodySubtitle={BP.bodySubtitle}
        formFields={BP.formFields}
        formContext={formContext}
        loading={props.loading}
        defaultValues={defaultValuesDS.get()}
        validateForm={validateForm}
        onValidateResult={handleValidateResult}
        onGetStarted={() => {
          setValidateForm(true);
        }}
        waveColor={theme?.palette.secondary}
        illustrationFillColor={theme?.palette.secondary}
      />
    </View>
  );
};
