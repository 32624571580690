import React, { useContext } from 'react';
import { LoginPageV } from 'react-login-template-2';
import { ThemeContext } from '../../../providers/theme';
import blueprint from '../config/default-blueprint.json';
import { useViewport } from 'react-viewport-provider';
import {View, Text, StyleSheet} from 'react-native';
import {AvatarV, AvatarSize} from 'react-native-avatar-vfw';
import {ButtonV} from 'react-button-rs';
import { useHistory, useLocation, Prompt } from 'react-router-dom';
import { EventBus } from 'event-bus-vfs';
import { FORM_VALUES, defaultValuesDS, FormV } from 'react-form-rs';
import { IProps } from './index';
import Alert from 'rsuite/lib/Alert';
import { Panel } from 'rsuite';

let formContext = {};
const BP = blueprint.LoginPage;

export const LoginPageSM = (props: IProps) => {
  const theme = useContext(ThemeContext);
  const { width, height, bp } = useViewport();
  const history = useHistory();
  const [date, setDate] = React.useState<Date>(new Date());
  const [validateForm, setValidateForm] = React.useState<any>(null);
  React.useEffect(() => {
    const app = EventBus.on(FORM_VALUES, value => {
      formContext = { ...value };
    });
    return function () {
      app.off();
    };
  }, []);

  const styles = {
    panelStyle:{
      backgroundColor:'#fff',
      width:width * 0.9,
      alignItems:'center',
      justifyContent:'center',
      margin:'40% auto',
    },
    logContainerStyle:{
      display:'flex',
      flexDirection:'column',
      alignItems:'center',
      justifyContent:'center'
    },
    titleStyle:{
      paddingLeft:5,
      fontSize:24,
      fontWeight:700
    },
    forgotPasswordStyle: {
      textAlign: 'center',
      color: 'blue'
    },
    descStyle:{
        display:'block', 
        padding:5,
        letterSpacing:2,
        lineHeight:20,
        fontSize:14,
        textAlign:'center'
    }
  }

  const handleValidateResult = (
    formContext: any,
    status: 'success' | 'error',
    validateResult: any,
  ) => {
    //console.log('defaultValuesDS', defaultValuesDS.get());
    if (status === 'success') {
    //  console.log('login data collected successful');
      props.onLogin &&
        props.onLogin(
          defaultValuesDS.get('emailAddress')?.defaultValue,
          defaultValuesDS.get('password')?.defaultValue,
          defaultValuesDS.get('rememberMe')?.defaultValue,
        );
      defaultValuesDS.clear();
    }
    if (status === 'error') {
      BP.loginFFEC && Alert.error(BP.loginFFEC, 10000);
    }
    setDate(new Date());
    setValidateForm(false);
  };

  const onForgotPasswordClick = () => {
    history.push('/forgotpassword');
  };

  return (
    <div style={{width:'100%', height:'100%', backgroundImage:`url("https://i.ibb.co/hKc68jG/amrut-building.png")`, backgroundRepeat: 'no-repeat', backgroundPosition:'left bottom', backgroundSize:'cover'}}>
    <Panel shaded style={StyleSheet.flatten([styles.panelStyle])}>
      <View style={StyleSheet.flatten([styles.logContainerStyle])}>
        <AvatarV
          source={{uri:BP?.leftAvatar}}
          size={AvatarSize?.Large}
        />
        <Text style={StyleSheet.flatten([styles.titleStyle])}>{BP.title}</Text>
        <Text style={StyleSheet.flatten([styles.descStyle])}>{BP?.mobilewarningMessage}</Text>
      </View>
    </Panel>
    {/* <LoginPageV
      containerStyle={{ width: 1 * width, height: 1 * height, backgroundColor:'transparent'}}
      title={BP.title}
      leftAvatar={BP.leftAvatar}
      loginButtonTitle={BP.loginButtonTitle}
      loginButtonColor={BP.loginButtonColor}
      signupButtonColor={theme?.palette.secondary}
      bodyTitle={BP.bodyTitle}
      formFields={BP.formFields}
      formContext={formContext}
      forgotPasswordText={"Forgot Password ?"}
      validateForm={validateForm}
      defaultValues={defaultValuesDS.get()}
      onHeaderLogoPress={() => alert('handle navigation')}
      onValidateResult={handleValidateResult}
      onGetStarted={() => {
       // console.log('get Started');
        setValidateForm({
          ...formContext,
        });
      }}
      waveColor={theme?.palette.secondary}
      imageBackgroundStyle={{backgroundColor:'transparent'}}
      // qrValue={BP.qrValue}
      // qrCodeSize={BP.qrCodeSize}
      // qrcodeDescription={BP.qrcodeDescription}
      onForgotPasswordClick={onForgotPasswordClick}
    /> */}
    </div>
  );
};
