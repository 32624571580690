import {useLazyQuery, useQuery, useMutation} from '@apollo/react-hooks';
import {useEffect} from 'react';
import {createNewDataPipeFrom, DataSet} from 'vis-data';
import { LOGIN_USER } from './mutation';
import BP from './config/default-blueprint.json';
import { GET_HOLDERS_DATA } from '../Dashboard/queries';

export const provDashboardSideNavDS = new DataSet({
    fieldId:'_id',
});

export const provDashboardSideNavTransformedDS = new DataSet ({
    fieldId:'_id'
});

export const useProvLogin = () => {

    useEffect(() => {
        return function cleanup() {
          provDashboardSideNavDS.clear();
        //   provDashboardSideNavTransformedDS.clear();
        };
      }, []);

// const pipeTransformation = () => {
//         //@ts-ignore
//         const pipe = createNewDataPipeFrom(provHoldersDS)
//           //@ts-ignore
//           .map((item: any) => ({
//             //
//           }))
//           //@ts-ignore
//           .to(provHoldersTransformedDS);
//         pipe.all().start();
//       };
    const [LoginUser, { 
        data:loginUserData, loading:loginUserLoading, error:loginUserError
    }] = useMutation(LOGIN_USER);

    if(loginUserData && loginUserData?.signIn){
        provDashboardSideNavTransformedDS.clear();
        let _data = loginUserData?.signIn?.features;

        Array.isArray(_data) && _data.map(item=>{
            if(item === "NETWORK_OPERATOR_ADMIN"){
                provDashboardSideNavTransformedDS.update(BP?.networkAdminSideNavItems);
            }
            else if(item === "NETWORK_OPERATOR"){
                provDashboardSideNavTransformedDS.update(BP?.networkAdminSideNavItems);
            }
            else if(item === "ISSUER_ADMIN"){
                provDashboardSideNavTransformedDS.update(BP?.issuerAdminSideNavItems);
            }
            else if(item === "VERIFIER_ADMIN"){
                provDashboardSideNavTransformedDS.update(BP?.verifierAdminSideNavItems);
            }
            else if(item === "ISSUER"){
                provDashboardSideNavTransformedDS.update(BP?.issuerSideNavItems);
            }
            else if(item === "VERIFIER"){
                provDashboardSideNavTransformedDS.update(BP?.verifierSideNavItems);
            }
            else {
                provDashboardSideNavTransformedDS.update(BP?.holderSideNavItems);
            }
        });
        
    }

    return {
     loginUserData,
     sideNavItems: provDashboardSideNavTransformedDS.get(),
     loginUserLoading,
     loginUserError,
     LoginUser
    };
};