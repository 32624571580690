import React from 'react';
import { LogoSpinnerV } from 'react-logo-spinner-ext';
import { useHistory, useLocation, Prompt } from 'react-router-dom';
import Alert from 'rsuite/lib/Alert';
import { ResetPasswordPage } from './components';
import {useProvDashboard} from '../Dashboard/provider';

export const ResetPassword = () => {

    const history = useHistory();
    const {resetPassword, resetPasswordData, resetPasswordError, resetPasswordLoading} = useProvDashboard();

    let url_string: string = window.location.href;
    const url = new URL(url_string);
    const email = url.searchParams.get("email");
    const token = url.searchParams.get("token");

    React.useEffect(() => {
        if (
            resetPasswordError &&
            resetPasswordError.graphQLErrors &&
            Array.isArray(resetPasswordError.graphQLErrors) &&
            resetPasswordError.graphQLErrors[0] &&
            resetPasswordError.graphQLErrors[0].message
        ) {
            Alert.error(resetPasswordError.graphQLErrors[0].message)
        }
    }, [resetPasswordError]);

    if (resetPasswordLoading) {
        return (<LogoSpinnerV />);
    }

    if (resetPasswordData) {
        Alert.success(resetPasswordData.changePassword)
        history.push('/');
    }

    const _handleREsetPassword = (password: string) => {
        resetPassword({
            variables: {
                email: email,
                password: password,
                passwordResetToken: token
            }
        });
    }

    const _renderBody = () => {
        return (
            <>
                <ResetPasswordPage onResetPassword={_handleREsetPassword} />
            </>
        )
    }

    return (
        <>
            {_renderBody()}
        </>
    )
}