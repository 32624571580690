import React, { useContext, useState, useEffect } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import config from '../../config/default-blueprint.json';
import { ThemeContext } from '../../../../providers/theme';
import { useViewport } from 'react-viewport-provider';
import { DashboardContainerV } from 'react-dashboard-container-rs';
import { DrawerV } from 'react-drawer-rs';
import { OrganizationProfile } from './components/OrganizationProfile';
import { OperatorProfile } from './components/OperatorProfile';
import { ProfileTemplate } from './components/ProfileTemplate';
import { IntroCard } from './components/IntroCard';
import { CredentailCategory } from './components/credentials/CredentialCategory';
import { CredentailList } from './components/credentials/components/credentialList';
import { IssueCredential } from './components/credentials/components/issueCredential';
import { Admins } from './components/Admins';
import { Verification } from './components/VerificationPage';
import { VerificationRequest } from './components/VerificationPage/components/VerificationSuite';
import { DashboardGraph } from './components/dashboard/Dashboard';
import { Feedback } from './components/feedback';
import { HolderGroups } from './components/holderGroups';
import { TrustNetwork } from './components/networks';
import { IssuersList } from './components/Issuers';
import { VerifiersList } from './components/Verifiers';
import { HoldersList } from './components/holders';
import { UserList } from './components/holderGroups/userlist/UserList';
import { Noterize } from './components/notarize';
import { CredentialView } from './components/networks/components/credential-view';
import { ProductView } from './components/batches';
import { BatchView } from './components/batches/components/BatchesList';
import { Transactions } from './components/transactions';
import { Enrolment } from './components/enrolment';
import { IProps } from './index';
import { CounterfeitAlert } from './components/counterfeitAlerts';
import Icon from 'rsuite/lib/Icon';

const humanizeString = require('humanize-string');
const BP = config.dashboard;



export const DashboardLG = (props: IProps) => {
  const theme = useContext(ThemeContext);
  const [drawer, setDrawer] = React.useState<any>({
    placement: 'right',
    title: '',
    size: 'xs',
    backdrop: false,
    show: false,
  });
  const [navBarEventKey, setNavBarEventKey] = React.useState<string>();
  const [sideBarEventKey, setSideBarEventKey] = React.useState<string>();
  const [formData, setFormData] = useState<any>();
  const [drawerWidth, setDrawerWidth] = useState(330);
  const { width, height } = useViewport();
  const [claims, setClaims] = useState<any>([]);

  const styles = StyleSheet.create({
    sidebarHeaderContainer: {
      alignItems: 'center',
      justifyContent: 'center',
      borderWidth: StyleSheet.hairlineWidth,
      height: 0.06 * height,
    },
    sidebarHeaderTitleStyle: {
      color: '#ffffff',
      fontWeight: 'bold',
      fontSize: 22,
    },
  });

  const RenderSidenavHeader = () => {
    return (
      <div
        style={{

          padding: 18,
          fontSize: 16,
          height: 56,
          // background: '#5987af',
          color: ' #fff',
          fontWeight: 'bold',
          whiteSpace: 'nowrap',
          overflow: 'hidden'
        }}
      >
        <Icon icon="logo-analytics" size="lg" style={{ verticalAlign: 0 }} />
        <span style={{ marginLeft: 18 }}>{BP.sidebarHeaderTitle}</span>
      </div >
    );
  };



  const renderContent = () => {
    if (sideBarEventKey === 'analytics') {
      return <DashboardGraph drawerWidth={drawerWidth} />;
    }
    else if (sideBarEventKey === 'feedback') {
      return <Feedback
        feedbacks={props?.feedbacks}
        feedbackPageChange={props?.feedbackPageChange}
        feedbackLoading={props?.feedbackLoading}
        feedbackStats={props?.feedbackStats}
        productsAndBatches={props?.productsAndBatches}
        onRatingFilter={props?.onRatingFilter}
        onFeedbackFilterApply={props?.onFeedbackFilterApply}
      />;
    }
    else if (sideBarEventKey === 'products') {
      return <ProductView
        onCardClick={eventKey => {
          setSideBarEventKey('batchView');
          props?.onProductSelect && props?.onProductSelect(eventKey);
        }}
        products={props?.products}

    />;
  }
  else if(sideBarEventKey === 'batchView'){
    return <BatchView 
    batches={props?.batches}
    onCardClick={(eventKey:string, title: string) => {
      props?.onBatchSelect && props?.onBatchSelect(eventKey, title);
    }}
    breadCrumbsClick={key => setSideBarEventKey(key)}
    onUpdateBatch={props?.onUpdateBatch}
    updateBatchSuccess={props?.updateBatchSuccess}
    activeGtin={props?.activeGtin}
    getQRData={props?.getQRData}
    />
  }
  else if(sideBarEventKey === 'transactions'){
    return <Transactions 
    transactions={props?.transactions}
    transactionStats={props?.transactionStats}
    tranactionsLoading={props?.tranactionsLoading}
    transactionsPageChange={props?.transactionsPageChange}
    productsAndBatches={props?.productsAndBatches}
    onFilterApply={props?.onFilterApply}
    scanCities={props?.scanCities}
    scanCountries={props?.scanCountries}
    scanRegions= {props?.scanRegions}
    completeTrasactions={props?.completeTrasactions}
    getFilteredTransactions={props?.getFilteredTransactions}
    />
  }
  else if(sideBarEventKey === 'enrolment'){
    return <Enrolment 
    enrolments={props?.enrolments}
    enrolmentStats={props?.enrolmentStats}
    enrolmentLoading={props?.feedbackLoading}
    enrolmentsPageChange={props?.enrolmentsPageChange}
    productsAndBatches={props?.productsAndBatches}
    onEnrolmentFilterApply={props?.onEnrolmentFilterApply}
    scanCities={props?.scanCities}
    scanCountries={props?.scanCountries}
    scanRegions= {props?.scanRegions}
    completeEnrolments={props?.completeEnrolments}
    getFilteredEnrolments={props?.getFilteredEnrolments}
    />
  }
  else if (sideBarEventKey === 'counterfeit'){
    return <CounterfeitAlert 
    counterfeitData={props?.counterfeitData}
    counterfeitStats={props?.counterfeitStats}
    counterfeitLoading={props?.counterfeitLoading}
    counterfeitPageChange={props?.counterfeitPageChange}
    counterfeitFilterApply={props?.counterfeitFilterApply}
    productsAndBatches={props?.productsAndBatches}
    scanCities={props?.scanCities}
    scanCountries={props?.scanCountries}
    scanRegions= {props?.scanRegions}
    />
  }
    return <OrganizationProfile
      orgProfileData={props?.orgProfileData}
    />

  };

  const renderFooter = () => {
    return <div />;
  };

  const handleOnNavItemSelect = (eventKey: string) => {
    setNavBarEventKey(eventKey);
    if (eventKey === 'notifications') {
      let _drawer = { ...drawer };
      _drawer.title = humanizeString(eventKey);
      _drawer.placement = BP.notificationsDrawerPlacement;
      _drawer.show = true;
      setDrawer({
        ..._drawer,
      });
    }
    if (eventKey === 'profile') {
      let _drawer = { ...drawer };
      _drawer.title = humanizeString(eventKey);
      _drawer.placement = BP.notificationsDrawerPlacement;
      _drawer.show = true;
      setDrawer({
        ..._drawer,
      });
    }
  };

  const handleOnSideNavItemselect = (eventKey: string) => {
    setSideBarEventKey(eventKey);
    props.sidebarNavItemSelect && props.sidebarNavItemSelect(eventKey);
  };

  const setExpanded = (eventKey: boolean) => {
    if (eventKey) {
      setDrawerWidth(330);
    } else {
      setDrawerWidth(120);
    }
  };

  const renderDrawerHeader = () => {
    return <div />;
  };

  const renderDrawerFooter = () => {
    return <div />;
  };

  const renderDrawerBody = () => {
    if (navBarEventKey === 'profile') {
      return <ProfileTemplate
        userProfile={props?.userProfile}
      />;
    }
    return <div />;
  };

  const handleOnDrawerHide = () => {
    let _drawer = { ...drawer };
    _drawer.show = false;
    setDrawer({
      ..._drawer,
    });
  };

  let _navBarItems = Array.isArray(BP?.navBarItems) && BP?.navBarItems.map(item => {
    if (item?.eventKey === 'profile') {
      return ({
        ...item,
        avatar: {
          src: props?.userProfile?.logo,
          title: props?.userProfile?.fullName.match(/\b(\w)/g)
        }
      });
    }
    else {
      return ({
        ...item
      });
    }
  })

  return (
    <>
      <DashboardContainerV
        navBarRightItems={_navBarItems}
        headerLogo={BP.headerLogo}
        headerTitle={BP.headerTitle}
        primaryColor={theme?.palette.primary}
        secondaryColor={theme?.palette.secondary}
        ternaryColor={theme?.palette.ternary}
        primaryHighlightColor={theme?.palette.primaryDark}
        sidebarWidth={180}
        sideBarItems={BP?.sidebarItems}
        renderContent={renderContent}
        headerTitleStyle={{}}
        navbarStyle={{}}
        onNavItemSelect={handleOnNavItemSelect}
        onSideNavItemSelect={handleOnSideNavItemselect}
        renderSidenavHeader={RenderSidenavHeader}
        drawerCloseIconColor={'#fff'}
        renderFooter={renderFooter}
        isexpanded={eventKey => setExpanded(eventKey)}
      />
      <DrawerV
        placement={drawer.placement}
        show={drawer.show}
        backdrop={drawer.backdrop}
        size={drawer.size}
        renderHeader={renderDrawerHeader}
        onHide={handleOnDrawerHide}
        drawerTitle={drawer.title}
        renderBody={renderDrawerBody}
        renderFooter={renderDrawerFooter}
      />
    </>
  );
};
