import React from 'react';
import { useViewport } from 'react-viewport-provider';
import { FeedbackLG } from './Feedback.lg';

export interface IProps {
    feedbacks?:any[];
    feedbackPageChange?(pageNo?: number, rowsPerPage?:string): void;
    feedbackLoading?:boolean;
    feedbackStats?: any[];
    productsAndBatches?:any[];
    onRatingFilter?(key?:any):void;
    onFeedbackFilterApply?(rowsPerPage: string, formData: any): void;
}


export const Feedback = (props: IProps) => {
    const { bp } = useViewport();

    // if (bp === 'xs' || bp === 'sm') {
    //     return <DashboardSM />;
    // }

    // if (bp === 'md') {
    //   return <DashboardMD />;
    // }

    //bp === 'lg' || bp === 'xl' || bp === 'md
    return <FeedbackLG {...props} />;
};
